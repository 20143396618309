import React, { FC } from 'react';

import IconCustom from 'components/common/IconCustom';

import { ITextBlockProps } from './model';

const TextBlock: FC<ITextBlockProps> = ({ text }) => (
  <div className="quote-block">
    <IconCustom icon="quote" />
    <p>{text}</p>
  </div>
);

export default TextBlock;
