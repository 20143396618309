import React, { FC } from 'react';

import GatsbyImage from 'components/common/GatsbyImage';

import { IFeaturedImageProps } from './model';
import './FeaturedImage.scss';

const FeaturedImage: FC<IFeaturedImageProps> = ({ img, alt }) => (
  <div className="featured-image">
    {img?.fallbackUrl ? <GatsbyImage fluid={img} alt={alt} /> : null}
  </div>
);

export default FeaturedImage;
