import React, { FC, useMemo } from 'react';
import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from '../DangerouslySetInnerHtml';
import { ItemsGridProps } from './model';

import './ItemsGrid.scss';
import useScreenRecognition from '../../../hooks/useScreenRecognition';

const ItemsGrid: FC<ItemsGridProps> = ({ text, grid, link }) => {
  const { isMobile } = useScreenRecognition();
  const imgStyle = useMemo(() => {
    if (isMobile) {
      return { width: '100%', height: 'auto' };
    }

    return { width: 'auto', height: '100%', left: '50%', transform: 'translateX(-50%)' };
  }, [isMobile]);

  const itemsGrid = useMemo(
    () =>
      grid?.map(({ properties: item }) => (
        <div className="grid-items-card" key={item.title}>
          <GatsbyImage
            fluid={item.image}
            alt={item.imageAlt}
            objectFit="initial"
            className="grid-items-card__image"
            imgStyle={imgStyle}
          />
          <div className="grid-items-card__text">
            <DangerouslySetInnerHtml className="grid-items-card__title" html={item.title} />
            {item.description ? (
              <DangerouslySetInnerHtml
                className="grid-items-card__description"
                html={item.description}
              />
            ) : null}
          </div>
        </div>
      )),
    [grid, imgStyle]
  );

  return (
    <div className="grid-wrapper">
      {text ? <DangerouslySetInnerHtml html={text} className="grid-description" /> : null}
      <div className="grid-items">{itemsGrid}</div>
      {link ? <DangerouslySetInnerHtml html={link} className="grid-link" /> : null}
    </div>
  );
};

export default ItemsGrid;
