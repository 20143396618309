const getLocalDate = (date: string, lang: string): string | null => {
  let localDate: string | null = null;
  if (date) {
    const dateString = new Date(date?.replace(/ /g, 'T'));
    localDate = dateString.toLocaleDateString(lang, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }

  return localDate;
};

export default getLocalDate;
